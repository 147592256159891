import React from "react"
import { graphql } from "gatsby"
import { Head } from "./sections/Head"
import { Header } from "./sections/Header"
import { Footer } from "./sections/Footer"
import "../styles/index.js"

export default function Template({ data = {} }) {
  const { markdownRemark = {} } = data
  const { frontmatter, html } = markdownRemark

  return (
    <div>
      <div className="container markdown px-4 pb-8">
        <Head />
        <Header />

        <div className="max-w-4xl mt-10 mx-auto">
          <h1 className="mb-5">{frontmatter.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`
